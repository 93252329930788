import React from "react";
import "./PoliticaPrivacidade.css";
import { Helmet } from "react-helmet";
import usePageTracking from "../../usePageTracking";

const PoliticaPrivacidade = () => {

  usePageTracking();

  return (
    <div className="politica-privacidade-container">
      <Helmet>
        <title>Política de Privacidade | GoCare Litoral</title>
        <meta name="description" content="Política de Privacidade da GoCare Litoral. Saiba como coletamos, usamos e protegemos suas informações pessoais." />
      </Helmet>
      <div className="politica-privacidade-content">
        <h1 className="politica-privacidade-title">Política de Privacidade</h1>
        <p className="politica-privacidade-date">Última atualização: 03/04/2025</p>

        <h2 className="politica-privacidade-subtitle">1. Introdução</h2>
        <p className="politica-privacidade-text">
          Esta Política de Privacidade tem como objetivo informar como coletamos,
          usamos, armazenamos e compartilhamos seus dados pessoais ao usar nosso site
          e serviços. Ao acessar nossos serviços, você concorda com as práticas descritas
          nesta política. 
          <br />
          Vale ressaltar que somos um portal de vendas de planos de saúde, realizando apenas
          a intermediação das informações entre você e o corretor, que é o responsável pelo
          contato e pela formalização do contrato com a operadora de plano de saúde. Apenas
          você, o corretor e a operadora de plano de saúde terão acesso às suas informações
          pessoais, conforme necessário para a execução do serviço contratado.
        </p>

        <h2 className="politica-privacidade-subtitle">2. Informações que coletamos</h2>
        <p className="politica-privacidade-text">
          Coletamos informações pessoais identificáveis, como nome, e-mail, telefone
          e endereço. Também coletamos dados de navegação, como informações sobre o seu dispositivo,
          endereço IP, tipo de navegador, e páginas visitadas, através do uso de cookies.
        </p>

        <h2 className="politica-privacidade-subtitle">3. Como utilizamos suas informações</h2>
        <p className="politica-privacidade-text">
          As informações coletadas são utilizadas para:
        </p>
        <ul className="politica-privacidade-list">
          <li>Fornecer e melhorar nossos serviços, facilitando a intermediação com o corretor de planos de saúde.</li>
          <li>Entrar em contato com você para atualizações de serviço e marketing.</li>
          <li>Garantir a segurança de nossa plataforma e cumprir com obrigações legais.</li>
        </ul>

        <h2 className="politica-privacidade-subtitle">4. Compartilhamento de Informações</h2>
        <p className="politica-privacidade-text">
          Não vendemos nem alugamos suas informações pessoais. Podemos compartilhar
          seus dados com terceiros somente em casos específicos:
        </p>
        <ul className="politica-privacidade-list">
          <li>Com o corretor de planos de saúde, que será responsável pelo contato direto com você e pela formalização do contrato com a operadora.</li>
          <li>Com a operadora de plano de saúde, para realizar a implantação do contrato e a formalização do plano.</li>
          <li>Com prestadores de serviços que ajudam a operar nossos serviços (ex: pagamentos, hospedagem de dados).</li>
          <li>Se exigido por lei, para cumprir com obrigações legais ou proteger nossos direitos.</li>
        </ul>

        <h2 className="politica-privacidade-subtitle">5. Cookies e Tecnologias de Rastreamento</h2>
        <p className="politica-privacidade-text">
          Utilizamos cookies para melhorar a experiência do usuário em nosso site. Cookies
          são pequenos arquivos armazenados no seu dispositivo para coletar dados sobre sua navegação.
          Você pode configurar seu navegador para recusar cookies ou ser alertado quando um cookie estiver sendo enviado.
        </p>

        <h2 className="politica-privacidade-subtitle">6. Seus Direitos</h2>
        <p className="politica-privacidade-text">
          Você tem o direito de acessar, corrigir, excluir ou restringir o uso dos seus dados pessoais a qualquer momento.
          Se desejar exercer esses direitos, entre em contato conosco através do e-mail vendas@gocarelitoral.com.br.
        </p>

        <h2 className="politica-privacidade-subtitle">7. Retenção de Dados</h2>
        <p className="politica-privacidade-text">
          Seus dados pessoais serão retidos pelo tempo necessário para cumprir os fins descritos nesta política,
          salvo se houver uma exigência legal para manter os dados por mais tempo.
        </p>

        <h2 className="politica-privacidade-subtitle">8. Segurança</h2>
        <p className="politica-privacidade-text">
          Utilizamos medidas de segurança para proteger suas informações pessoais, mas não podemos garantir
          a total segurança contra acessos não autorizados ou ataques cibernéticos.
        </p>

        <h2 className="politica-privacidade-subtitle">9. Transferência Internacional de Dados</h2>
        <p className="politica-privacidade-text">
          Seus dados podem ser transferidos e processados fora do seu país de residência, onde as leis de privacidade
          podem ser diferentes. Garantimos que todos os dados são tratados de acordo com as regulamentações de proteção
          de dados aplicáveis.
        </p>

        <h2 className="politica-privacidade-subtitle">10. Alterações nesta Política</h2>
        <p className="politica-privacidade-text">
          Podemos atualizar esta Política de Privacidade periodicamente. Quaisquer alterações serão publicadas
          nesta página, com a data da última atualização.
        </p>

        <h2 className="politica-privacidade-subtitle">11. Contato</h2>
        <p className="politica-privacidade-text">
          Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade,
          entre em contato conosco através do e-mail <a href="mailto:vendas@gocarelitoral.com.br" className="politica-privacidade-link">vendas@gocarelitoral.com.br</a>.
        </p>
      </div>
    </div>
  );
};

export default PoliticaPrivacidade;
