import { Link, useLocation } from "react-router-dom";
import "./NewNavOptions.css";

const NewNavOptions = () => {
  const location = useLocation();

  return (
    <div className="header__bottom">
      <nav className="bottom__menu__nav">
        <ul className="bottom__menu__ul">
          <li className="bottom__menu__li">
            <Link
              to="/autorizacao"
              className={`bottom__header__link ${
                location.pathname === "/autorizacao" ? "active" : ""
              }`}
            >
              <span className="bottom__hover">Autorização</span>
            </Link>
          </li>

          <li className="bottom__menu__li">
            <Link
              to="/boleto"
              className={`bottom__header__link ${
                location.pathname === "/boleto" ? "active" : ""
              }`}
            >
              <span className="bottom__hover">Boleto</span>
            </Link>
          </li>

          <li className="bottom__menu__li">
            <Link
              to="/cartao-beneficiario"
              className={`bottom__header__link ${
                location.pathname === "/cartao-beneficiario" ? "active" : ""
              }`}
            >
              <span className="bottom__hover">Cartão de Beneficiário</span>
            </Link>
          </li>

          <li className="bottom__menu__li">
            <a
              href="https://new.gssgocare.com.br/#/procedimentos-grupo-carencia"
              className="bottom__header__link"
              target="_blank" // Se quiser abrir em nova aba
              rel="noopener noreferrer" // Boa prática de segurança
            >
              <span className="bottom__hover">Grupos de Carência</span>
            </a>
          </li>

          <li className="bottom__menu__li">
            <Link
              to="/consultas"
              className={`bottom__header__link ${
                location.pathname === "/consultas" ? "active" : ""
              }`}
            >
              <span className="bottom__hover">Consultas</span>
            </Link>
          </li>

          <li className="bottom__menu__li">
            <a
              href="https://new.gssgocare.com.br/#/rede-credenciada"
              className="bottom__header__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="bottom__hover">Rede Credenciada</span>
            </a>
          </li>

          <li className="bottom__menu__li">
            <Link
              to="/telemedicina"
              className={`bottom__header__link ${
                location.pathname === "/telemedicina" ? "active" : ""
              }`}
            >
              <span className="bottom__hover">Telemedicina</span>
            </Link>
          </li>

          <li className="bottom__menu__li">
            <Link
              to="/aplicativo"
              className={`bottom__header__link ${
                location.pathname === "/aplicativo" ? "active" : ""
              }`}
            >
              <span className="bottom__hover">Baixe o App</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NewNavOptions;
