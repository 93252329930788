import './styles/reset.css';
import './styles/App.css';

import Header from './components/header/Header';
import Main from './components/main/Main';
import Footer from './components/footer/Footer';

import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import "./hooks/LGPD/LGPD";


import GTMNoScript from './hooks/GTMNoScripts';
import LgpdModal from './hooks/LGPD/LGPD';

function App() {
  return (
    <Router>
      <div className="App">
        <GTMNoScript/>
        <Helmet>
        
          {/* Google Tag Manager */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16646173265"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16646173265');
            `}
          </script>
        </Helmet>

        <LgpdModal />

        <Header />
        <Main />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
