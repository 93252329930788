import "./Home.css";
import { Helmet } from "react-helmet";

import Carousel from "./carousel/Carousel.js";
import BenefitsCarousel from "./benefits/Benefits.js";
import usePageTracking from "../../../hooks/usePageTracking.js";
import useSchemaOrg from "../../../hooks/SchemaOrg.js";

import family from "../../../assets/img-main/family.webp";
// import ModalAviso from "./ModalHospital/ModalAviso.js";

const Home = () => {
  usePageTracking();

const customData = {
  "description": "Conheça o melhor e mais barato plano de saúde da Baixada Santista. Contrate online GoCare Saúde: serviços médicos de qualidade e atendimento humanizado com carência reduzida para o Litoral. Garanta agora mais saúde e bem-estar com facilidade e conveniência.",
};


  useSchemaOrg(customData);

  return (
    <div className="home">
      <Helmet>
        <link rel="canonical" href="https://www.gocarelitoral.com.br" />
        <title>
          GoCare Litoral | Planos de Saúde na Baixada Santista
        </title>
        <meta
          name="description"
          content="Conheça o melhor e mais barato plano de saúde da Baixada Santista. Contrate online GoCare Saúde: serviços médicos de qualidade e atendimento humanizado com carência reduzida para o Litoral. Garanta agora mais saúde e bem-estar com facilidade e conveniência."
        />

        <meta
          name="keywords"
          content="planos de saúde, GoCare, Baixada Santista, cobertura completa, melhores hospitais, litoral, plano de saude barato, tabela de valores, convênio, convênio barato, contratar plano de saúde, solicitar cotação" />
        <meta name="author" content="GoCare Litoral" />
        <meta property="og:title" content="GoCare Litoral | Planos de Saúde na Baixada Santista" />
        <meta property="og:description" content="Conheça o melhor e mais barato plano de saúde da Baixada Santista. Contrate online GoCare Saúde: serviços médicos de qualidade e atendimento humanizado com carência reduzida para o Litoral. Garanta agora mais saúde e bem-estar com facilidade e conveniência." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GoCare Litoral" />
        <meta property="og:url" content="https://www.gocarelitoral.com.br/" />
        <meta property="og:image" content={family} />
        


      </Helmet>

      {/* <ModalAviso /> */}
      <Carousel />
      <BenefitsCarousel />
    </div>
  );
};

export default Home;
