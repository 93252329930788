import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import './Client.css';

import logo2 from "../../../assets/logo2.webp";
import cliente_info from "../../../assets/img-cliente/cliente-info.webp";

import family from "../../../assets/img-main/family.webp";

import Form from './form/Form.js';
import usePageTracking from '../../../hooks/usePageTracking.js';
import useSchemaOrg from '../../../hooks/SchemaOrg.js';


function Client() {
  usePageTracking();

  const customData = {
    "description": "Torne-se cliente da GoCare Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa na Baixada Santista. Contrate seu plano de saúde de forma rápida e fácil.",
    "url": "https://gocarelitoral.com.br/cliente",
  };

  // Schema.org data for the Client page
  useSchemaOrg(customData);

  const navigate = useNavigate();

  const handleFormSubmitted = () => {
    navigate('/confirmacao');
  };

  return (
    <div className="contact">
      <Helmet>
        <link rel="canonical" href="https://www.gocarelitoral.com.br/cliente" />
        <title>Seja Cliente | GoCare Litoral</title>
        <meta name="description" content="Torne-se cliente da GoCare Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa na Baixada Santista. Contrate seu plano de saúde de forma rápida e fácil." />

      <meta name="keywords" content="cliente, planos de saúde, GoCare, Baixada Santista, cobertura completa, melhores hospitais, litoral, plano de saude barato, tabela de valores, convênio, convênio barato, contratar plano de saúde, solicitar cotação" />
        <meta name="author" content="GoCare Litoral" />
        <meta property="og:title" content="Seja Cliente | GoCare Litoral" />
        <meta property="og:description" content="Torne-se cliente da GoCare Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa na Baixada Santista. Contrate seu plano de saúde de forma rápida e fácil." />
        <meta property="og:url" content="https://www.gocarelitoral.com.br/cliente" />
        <meta property="og:image" content={family} />

      </Helmet>

      <div className="contact__info">

        <img  className="contact__logo" src={logo2} alt="Logo da empresa" />

        <div className='contact__subtitle__container'>

          <p className="contact__subtitle">A <span>GoCare</span> é<br /> para sua <span>família</span>,<br /> para sua <span>empresa</span>,<br /> a <span>GoCare</span> é para <span>você</span>!<br /></p>
          <p className="contact__p">Economize até 40% utilizando <br />seu CNPJ a partir de 1 vida!</p>

        </div>

        <img  className='contact__cliente__img' src={cliente_info} alt='Imagem de uma família' />

      </div>

      <div className='form_grid'>

        <Form onFormSubmitted={handleFormSubmitted} />

      </div>

    </div>
  );
}

export default Client;
