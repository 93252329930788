import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Form.css";

const Form = ({ onFormSubmitted }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [city, setCity] = useState("");
  const [life, setLife] = useState("");
  const [planType, setPlanType] = useState("");
  const from_site = "GoCare Litoral";

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      email: email,
      city: city,
      tel: tel,
      life: life,
      planType: planType,
      from_site: from_site,
    };

    emailjs
      .send(
        "service_planosdesaude",
        "template_planosdesaude",
        templateParams,
        "ohUbTMeJ26lIdw99F"
      )
      .then(() => {
        onFormSubmitted();
        setName("");
        setEmail("");
        setTel("");
        setCity("");
        setLife("");
        setPlanType("");

        // Call gtagSendEvent to track conversion
        if (typeof window !== "undefined" && window.gtagSendEvent) {
          window.gtagSendEvent();
        }
      });
  }

  return (
    <div className="form__container">
      <p className="form__title">FAÇA JÁ A SUA COTAÇÃO</p>
      <form className="contact__form" onSubmit={sendEmail}>
        <div className="input__group">
          <label className="input__label" htmlFor="name">
            Nome <span>*</span>
          </label>
          <input
            className="input"
            id="name"
            type="text"
            placeholder="Digite o seu nome"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </div>

        <div className="input__group">
          <label className="input__label" htmlFor="email">
            Email <span>*</span>
          </label>
          <input
            className="input"
            id="email"
            type="email"
            placeholder="Digite o seu email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </div>

        <div className="input__group">
          <label className="input__label" htmlFor="tel">
            Telefone <span>*</span>
          </label>
          <input
            className="input"
            id="tel"
            type="tel"
            pattern="[0-9]*"
            placeholder="DDD + Seu número"
            onChange={(e) => setTel(e.target.value)}
            value={tel}
            required
          />
        </div>

        <div className="input__group">
          <label className="input__label" htmlFor="city">
            Cidade <span>*</span>
          </label>
          <input
            className="input"
            id="city"
            type="text"
            placeholder="Digite a sua cidade"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            required
          />
        </div>

        <div className="input__group">
          <label className="input__label" htmlFor="life">
            Número de Vidas <span>*</span>
          </label>
          <input
            className="input"
            id="life"
            type="number"
            placeholder="0"
            onChange={(e) => setLife(e.target.value)}
            value={life}
            required
          />
        </div>

        <div className="input__group">
          <label className="input__label" htmlFor="planType">
            Tipo de Plano <span>*</span>
          </label>
          <select
            className="input"
            id="planType"
            onChange={(e) => setPlanType(e.target.value)}
            value={planType}
            required
          >
            <option value="" disabled>
              Selecione o tipo de plano
            </option>
            <option value="Individual">Individual</option>
            <option value="Familiar">Familiar</option>
            <option value="Empresarial">Empresarial</option>
            <option value="Adesao">Adesão</option>
            <option value="Adesao_Estudantil">Adesão Estudantil</option>
            {/* <option value="Odontologico">Odontológico</option> */}
          </select>
        </div>

        <input
          id="btn-cotacao"
          className="button"
          type="submit"
          value="RECEBER COTAÇÃO"
        />
      </form>

      <p className="declaracao">
        Ao clicar no botão <span>“RECEBER COTAÇÃO”</span>, você declara a
        permissão para coletarmos seus dados e que está de acordo com nossas
        políticas de privacidade.
      </p>
    </div>
  );
};

export default Form;
