import './Planos.css'
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import usePageTracking from '../../../hooks/usePageTracking.js';
import CardPlanos from './CardPlanos/CardPlanos.js';
import CopModal from './CopModal/CopModal.js';
// import ModalAvisoReajuste from './ReajusteModal/ReajusteModal.js';

import bannerAdesao from '../../../assets/img-main/carousel/banner_adesao.webp';
import bannerAdesaoMobile from '../../../assets/img-main/carousel/banner_adesao_mobile.webp';
import bannerEmpresarial from '../../../assets/img-main/carousel/banner_empresarial.webp';
import bannerEmpresarialMobile from '../../../assets/img-main/carousel/banner_empresarial_mobile.webp';
import bannerIndFam from '../../../assets/img-main/carousel/banner_indfam.webp';
import bannerIndFamMobile from '../../../assets/img-main/carousel/banner_indfam_mobile.webp';
import bannerAdesaoEstudantil from '../../../assets/img-main/carousel/banner_adesaoestudantil.webp';
import bannerAdesaoEstudantilMobile from '../../../assets/img-main/carousel/banner_adesaoestudantil_mobile.webp';
import useSchemaOrg from '../../../hooks/SchemaOrg.js';

const Planos = () => {
    
    const customData = {
        "description": "Explore os diversos planos de saúde oferecidos pela GoCare Saúde no Litoral. Planos individuais, familiares e empresariais com cobertura completa e atendimento humanizado na Baixada Santista.",
        "url": "https://gocarelitoral.com.br/planos",
        "offers": [
            {
                "@type": "Offer",
                "name": "GoCare Litoral A",
                "priceCurrency": "BRL",
                "price": "145.61",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerAdesao), String(bannerAdesaoMobile)],
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial A",
                "priceCurrency": "BRL",
                "price": "187.77",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerAdesao), String(bannerAdesaoMobile)],
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral E",
                "priceCurrency": "BRL",
                "price": "129.37",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos. Economize até 40% utilizando seu CNPJ a partir de  1 vida!",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerEmpresarial), String(bannerEmpresarialMobile)],
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial E",
                "priceCurrency": "BRL",
                "price": "148.78",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos. Economize até 40% utilizando seu CNPJ a partir de  1 vida!",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerEmpresarial), String(bannerEmpresarialMobile)],
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral I",
                "priceCurrency": "BRL",
                "price": "207.00",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerIndFam), String(bannerIndFamMobile)],
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial I",
                "priceCurrency": "BRL",
                "price": "238.05",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerIndFam), String(bannerIndFamMobile)],

            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral A Adesão Estudantil",
                "priceCurrency": "BRL",
                "price": "141.74",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerAdesaoEstudantil), String(bannerAdesaoEstudantilMobile)],
            },
            {
                "@type": "Offer",
                "name": "GoCare Litoral Especial A Adesão Estudantil",
                "priceCurrency": "BRL",
                "price": "182.78",
                "description": "Preço mínimo válido para a faixa etária de 00 a 18 anos.",
                "eligibleRegion": {
                    "@type": "Place",
                    "name": "Baixada Santista, SP"
                },
                "url": "https://gocarelitoral.com.br/planos",
                "availability": "http://schema.org/InStock",
                "image": [String(bannerAdesaoEstudantil), String(bannerAdesaoEstudantilMobile)],
            }
        ]
    };



    useSchemaOrg(customData);

    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    usePageTracking();

    return (
        <section className="page__plans-section">

            <Helmet>
                <link rel="canonical" href="https://www.gocarelitoral.com.br/planos" />
                <title>Planos de Saúde GoCare na Baixada Santista | Contrate Online de Forma Simples</title>
                <meta name="description"
                    content="Explore os diversos planos de saúde oferecidos pela GoCare Saúde no Litoral. Planos individuais, familiares e empresariais com cobertura completa e atendimento humanizado na Baixada Santista." />

                <meta name="keywords" content="planos de saúde, GoCare, Baixada Santista, cobertura completa, melhores hospitais, litoral, plano de saude barato, tabela de valores, convênio, convênio barato, contratar plano de saúde, solicitar cotação" />
                <meta name="author" content="GoCare Litoral" />
                <meta property="og:title" content="Planos de Saúde GoCare na Baixada Santista | Contrate Online de Forma Simples" />
                <meta property="og:description" content="Explore os diversos planos de saúde oferecidos pela GoCare Saúde no Litoral. Planos individuais, familiares e empresariais com cobertura completa e atendimento humanizado na Baixada Santista." />
                <meta property="og:url" content="https://www.gocarelitoral.com.br/planos" />
                <meta property="og:image" content={bannerAdesao} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="GoCare Litoral" />

            </Helmet>

            <div className="page__plans-container">
                <div className="page__plans-text">
                    <div className="page__plans-title">
                        <h2><strong>Valores e Coberturas</strong> de <strong>Planos de Saúde<br /></strong> para o <strong>Litoral</strong></h2>
                    </div>
                    <div className="div-separate"></div>
                </div>

                <CardPlanos />

                {/* <ModalAvisoReajuste /> */}

                <div className='modal__container'>
                    <p>
                        Clique no botão a seguir para visualizar a tabela de coparticipação com os valores máximos de terapias, procedimentos e materiais importados.
                    </p>
                    <button onClick={openModal}>
                        Ver Tabela de Coparticipação
                    </button>
                </div>

                <CopModal showModal={showModal} closeModal={closeModal} />

            </div >

        </section >

    )
}

export default Planos;