import { Helmet } from "react-helmet";

import "./About.css";

import AboutInfo from "./aboutinfo/AboutInfo.js";
import Abrangencia from "./abrangencia/Abrangencia.js";
import Mission from "./mission/Mission.js";
import Cobertura from "./cobertura/Cobertura.js";
import usePageTracking from "../../../hooks/usePageTracking.js";

import family from "../../../assets/img-main/family.webp";
import useSchemaOrg from "../../../hooks/SchemaOrg.js";

const About = () => {
  usePageTracking();

    // Schema.org data for the About page
    const customData = {
        "description": "Conheça a GoCare Saúde no Litoral! Oferecemos planos de saúde acessíveis e completos, pensados para o bem-estar de você e sua família na Baixada Santista. Descubra nossa missão de cuidar do que importa: sua saúde.",
        "url": "https://gocarelitoral.com.br/sobre",
    };

    useSchemaOrg(customData);

  return (
    <div className="about__container">
      <Helmet>
        <link rel="canonical" href="https://www.gocarelitoral.com.br/sobre" />
        <title>Sobre a GoCare | GoCare Litoral</title>
        <meta
          name="description"
          content="Conheça a GoCare Saúde no Litoral! Oferecemos planos de saúde acessíveis e completos, pensados para o bem-estar de você e sua família na Baixada Santista. Descubra nossa missão de cuidar do que importa: sua saúde."
        />

        <meta
          name="keywords"
          content="sobre, GoCare, planos de saúde, Baixada Santista, cobertura completa, atendimento humanizado, saúde acessível, missão, visão, valores" />
        <meta name="author" content="GoCare Litoral" />
        <meta property="og:title" content="Sobre a GoCare | GoCare Litoral" />
        <meta property="og:description" content="Conheça a GoCare Saúde no Litoral! Oferecemos planos de saúde acessíveis e completos, pensados para o bem-estar de você e sua família na Baixada Santista. Descubra nossa missão de cuidar do que importa: sua saúde." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GoCare Litoral" />
        <meta property="og:url" content="https://www.gocarelitoral.com.br/sobre" />
        <meta property="og:image" content={family} />

      </Helmet>

      <section className="about__banner-container">
        <div className="about__banner">
          <img
            className="banner__img"
            src={family}
            alt="Imagem de uma família"
          />
        </div>

        <AboutInfo />
      </section>

      <section className="additional__info">
        <Mission />
      </section>

      <section className="abrangencia">
        <Abrangencia />
      </section>

      <section className="cobertura">
        <Cobertura />
      </section>
    </div>
  );
};

export default About;
