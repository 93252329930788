import { Helmet } from "react-helmet";
import "./boleto.css";
import usePageTracking from "../../../hooks/usePageTracking";

export const Boleto = () => {
  usePageTracking();

  return (
    <section className="boleto-section">
      <Helmet>
        <link rel="canonical" href="https://www.gocarelitoral.com.br/boleto" />
        <title>Como Solicitar meu Boleto | GoCare Litoral</title>
        <meta
          name="description"
          content="Encontre aqui todas as informações necessárias para gerar o boleto de acordo com sua administradora ou modalidade do seu plano de saúde."
        />
      </Helmet>
      <div className="boleto-container">
        {/* Bloco Pessoa Física ou Empresarial */}
        <div className="boleto-block boleto-pessoa-fisica">
          <div>
            <h4 className="boleto-title">PESSOA FÍSICA ou EMPRESARIAL</h4>
            <p className="boleto-text">
              Caso você seja um cliente individual ou empresarial, pode
              solicitar seu boleto diretamente pelo portal dedicado. Este
              recurso é fácil, rápido e acessível para garantir a continuidade
              do seu atendimento.
            </p>
          </div>

          <a
            href="https://new.gssgocare.com.br/#/boletos"
            className="boleto-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Solicitar Boleto
          </a>
        </div>

        {/* Bloco Bauen Life */}
        <div className="boleto-block">
          <div>
            <h4 className="boleto-title">BAUEN LIFE administradora</h4>
            <p className="boleto-text">
              Acesse o portal, clique em "área do cliente" no canto superior
              esquerdo e tenha acesso a serviços como a solicitação da segunda
              via de carteirinha, segunda via de boleto, informe de pagamentos,
              procedimentos médicos e cancelamento do plano.
            </p>
          </div>

          <div className="boleto-contact">
            <div className="boleto-contact-block">
              <i className="boleto-icon fa-solid fa-phone"></i>
              <span>(13) 3221-8280</span>
            </div>
            <div className="boleto-contact-block">
              <i className="boleto-icon fa-solid fa-phone"></i>
              <span>(13) 99112-2729</span>
            </div>
          </div>
          <a
            href="https://www.bauenlife.com.br"
            className="boleto-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bauen Life
          </a>
        </div>

        {/* Bloco Lexus */}
        <div className="boleto-block">
          <div>
            <h4 className="boleto-title">LEXUS Saúde Administradora</h4>
            <p className="boleto-text">
              Faça login e digite o CPF do titular do plano. A senha é composta
              pelas três primeiras letras do nome do titular (com a primeira
              letra maiúscula), seguidas pelo primeiro, terceiro e quinto
              dígitos do CPF.
              <br />
              No menu principal, selecione a opção <strong>"Boletos"</strong>.
              Escolha a opção <strong>"Segunda via de boletos"</strong>.
              <br />
              Pronto! Seu boleto estará disponível para visualização e download.
            </p>
          </div>

          <div className="boleto-contact">
            <div className="boleto-contact-block">
              <i className="boleto-icon fa-solid fa-phone"></i>
              <span>0800 995 0422</span>
            </div>
            <div className="boleto-contact-block">
              <i className="boleto-icon fa-solid fa-envelope"></i>
              <span>contato@lexussaude.com.br</span>
            </div>
          </div>

          <a
            href="https://lexus.sign1.com.br/"
            className="boleto-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lexus Saúde
          </a>
        </div>
      </div>
    </section>
  );
};
