import "./Duvidas.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { Faq } from "./Faq/Faq.js";
import usePageTracking from "../../../hooks/usePageTracking.js";
import wpplogo from "../../../assets/img-main/wpplogo.webp";
import handshake from "../../../assets/img-main/handshake.webp";
import useSchemaOrg from "../../../hooks/SchemaOrg.js";

export const Duvidas = () => {
  const navigate = useNavigate();
  usePageTracking();

  const customData = {
    "description": "Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da GoCare Saúde no Litoral. Saiba tudo sobre valores, carências, coberturas e benefícios dos nossos planos de saúde na Baixada Santista.",
    "url": "https://gocarelitoral.com.br/duvidas",
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "Quais são as opções de planos da GoCare Litoral?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "A GoCare Litoral oferece diferentes planos com cobertura regional e nacional, incluindo opções com ou sem coparticipação.",
                    },
                  },
                  {
                    "@type": "Question",
                    "name": "Como faço para contratar um plano?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Você pode contratar um plano entrando em contato pelo WhatsApp, telefone ou preenchendo o formulário em nosso site.",
                    },
                  },
                  {
                    "@type": "Question",
                    "name": "Os planos possuem período de carência?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Sim, todos os planos possuem carência, que varia conforme o tipo de serviço. Consulte nossa equipe para mais detalhes.",
                    },
                  },
                  {
                    "@type": "Question",
                    "name": "Posso utilizar a telemedicina com meu plano?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Sim, a GoCare Litoral oferece telemedicina para consultas online. Basta acessar o app ou entrar em contato para mais informações.",
                    },
                  },
                  {
                    "@type": "Question",
                    "name": "Como acessar a carteirinha digital?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "A carteirinha digital está disponível no aplicativo da GoCare. Basta baixar o app e fazer login para acessar.",
                    },
                  },
                ],
  };

  useSchemaOrg(customData);

  return (
    <section className="duvidas">
      <Helmet>
        <link rel="canonical" href="https://www.gocarelitoral.com.br/duvidas" />
        <title>Dúvidas Frequentes | GoCare Litoral</title>
        <meta
          name="description"
          content="Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da GoCare Saúde no Litoral. Saiba tudo sobre valores, carências, coberturas e benefícios dos nossos planos de saúde na Baixada Santista."
        />

        <meta
          name="keywords"
          content="duvidas, perguntas frequentes, planos de saúde, GoCare, Baixada Santista, cobertura completa, melhores hospitais, litoral, plano de saude barato, tabela de valores, convênio, convênio barato, contratar plano de saúde, solicitar cotação" />
        <meta name="author" content="GoCare Litoral" />

        <meta property="og:title" content="Dúvidas Frequentes | GoCare Litoral" />
        <meta
          property="og:description"
          content="Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da GoCare Saúde no Litoral. Saiba tudo sobre carências, coberturas e benefícios dos nossos planos de saúde na Baixada Santista."/>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GoCare Litoral" />
        <meta property="og:url" content="https://www.gocarelitoral.com.br/duvidas" />
        

      </Helmet>

      <div className="duvidas-vendas">
        <div className="bg-duvidas-title titulo-duvidas">
          <h2>ATENDIMENTO EXCLUSIVO PARA VENDAS</h2>
        </div>

        <div className="margin-duvidas">
          <p className="vendas-text">
            Quer saber mais sobre nossos planos e como escolher a melhor opção
            para você? Nossos canais de atendimento estão prontos para te
            ajudar!
          </p>

          <div className="canais-vendas-container">
            <div className="canais-vendas">
              <button
                className="canais-vendas-buttons canal__whatsapp"
                onClick={() =>
                  window.open(
                    "https://wa.me/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral.",
                    "_blank"
                  )
                }
              >
                <img src={wpplogo} alt="Logo do whatsapp" />
                <p>Solicitar Cotação</p>
              </button>
            </div>

            <div className="canais-vendas">
              <button
                className="canais-vendas-buttons"
                onClick={() => navigate("/cliente")}
              >
                <img src={handshake} alt="Icone de vendas" />
                <p>Solicitar Cotação</p>
              </button>
            </div>
          </div>

          <p className="vendas-text">
            Se preferir, envie um e-mail para{" "}
            <a
              className="vendas-atendimento-email"
              href="mailto:vendas@gocarelitoral.com.br"
            >
              vendas@gocarelitoral.com.br
            </a>{" "}
            com sua solicitação e nossa equipe responderá o mais breve possível.
          </p>
        </div>
      </div>
      <Faq />
    </section>
  );
};
