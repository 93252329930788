import { useState, useEffect } from "react";
import "./LGPD.css";

const LgpdModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Verifica se o usuário já aceitou a política de privacidade
    const lgpdAccepted = localStorage.getItem("lgpdAccepted");
    if (lgpdAccepted !== "true") {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("lgpdAccepted", "true");
    setIsVisible(false);
  };

  const handleDecline = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="lgpd-overlay">
        <div className="lgpd-modal">
          <p>
            Usamos cookies para melhorar sua experiência. Ao continuar navegando,
            você concorda com nossa <a href="/politica-de-privacidade">Política de Privacidade</a>.
          </p>
          <div className="lgpd-buttons">
            <button className="accept" onClick={handleAccept}>Aceitar</button>
            <button className="decline" onClick={handleDecline}>Recusar</button>
          </div>
        </div>
      </div>
    )
  );
};

export default LgpdModal;
